/** @jsxImportSource @emotion/react */
import {
  createContext,
  CSSProperties,
  forwardRef,
  PropsWithChildren,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Clear } from '@mui/icons-material';
import { Button } from '@mui/material';
import useSimpleModal from './useSimpleModal';
import { css, keyframes } from '@emotion/react';
import Row from '../../layouts/Row';
import defaultModalButtons, { ButtonItem } from '../Buttons/DefaultModalButtons';
import { ButtonActions, ButtonContextType, useButtonContext } from './useButtonContext';

interface SimpleModalProps {
  content?: ReactElement | ReactElement[];
  showModal?: boolean;
  canCloseClickingOutside?: boolean;
  style?: CSSProperties;
}

interface WithOnClose extends SimpleModalProps {
  onClose: CallableFunction;
  buttonList?: never;
}

interface WithButtonList extends SimpleModalProps {
  onClose?: never;
  buttonList: ButtonItem[];
}

interface WithoutBoth extends SimpleModalProps {
  onClose?: never;
  buttonList?: never;
}

type TSimpleModalProps = WithOnClose | WithButtonList | WithoutBoth;

const initialActions: ButtonActions = {
  close: { call: () => undefined, disabled: true },
  exit: { call: () => undefined, disabled: true },
  refresh: { call: () => undefined, disabled: true },
  save: { call: () => undefined, disabled: true },
};
export const ButtonContext = createContext<ButtonContextType | null>(null);
export const ButtonContextProvider = ({
  children,
  initialActions,
}: PropsWithChildren & {
  initialActions: ButtonActions;
}): ReactElement => {
  const [buttonActions, setButtonActions] = useState<ButtonActions>(initialActions);
  const { callbacks } = useSimpleModal();
  callbacks.current = { setButtonActions };

  return <ButtonContext.Provider value={{ buttonActions, setButtonActions }}>{children}</ButtonContext.Provider>;
};

const CloseButtons = ({ onClose, buttonList }: Pick<TSimpleModalProps, 'onClose' | 'buttonList'>): ReactElement => {
  const { buttonActions } = useButtonContext();
  const { clearModal, afterClearModal } = useSimpleModal();

  return onClose || !buttonList ? (
    <Row columns={1} columnWidth={'1fr'} style={{ height: 'min-content', minHeight: '45px', justifyItems: 'end' }}>
      <Button
        data-testid={'btn-clear'}
        color={'inherit'}
        onClick={onClose ? (): void => onClose() : (): void => clearModal(afterClearModal)}
        sx={{ width: 'min-content', minWidth: 'min-content' }}
      >
        <Clear fontSize={'medium'} color={'error'} />
      </Button>
    </Row>
  ) : (
    <Row display={'flex'} style={{ height: 'min-content', justifyContent: 'flex-start' }}>
      {buttonList.map((button) => {
        return !(buttonActions[button.id] && buttonActions[button.id].hidden) ? (
          <Button
            type={button.type ? 'submit' : 'button'}
            data-testid={`btn-${button.id}`}
            key={`modal_close_btn_${button.id}`}
            color={button.color ?? 'inherit'}
            onClick={(): void => {
              buttonActions[button.id] ? buttonActions[button.id].call() : button.onClick();
            }}
            disabled={buttonActions[button.id] ? buttonActions[button.id].disabled : button.disabled}
            sx={
              button.style ?? {
                justifyContent: 'start',
                width: 'min-content',
                minWidth: 'min-content',
                marginLeft: button.id === 'close' ? 'auto' : 0,
              }
            }
          >
            {button.icon}
          </Button>
        ) : null;
      })}
    </Row>
  );
};
const slide = keyframes({
  from: {
    transform: 'translate(100%,0)',
  },
  to: {
    transform: 'translate(0,0)',
  },
});

const classSimpleModal = css({
  '.slide': {
    animation: `${slide} .35s ease-out 1`,
  },
});

const SimpleModal = forwardRef((props: TSimpleModalProps, ref: any): ReactElement | null => {
  const { content, showModal, onClose, canCloseClickingOutside = false, style = {}, buttonList } = props;
  const { show, clearModal, modalContent, modalStyle, afterClearModal } = useSimpleModal();
  const shrinkMe = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    return () => {
      clearModal();
    };
  }, []);

  return showModal || show ? (
    <div
      ref={ref}
      css={classSimpleModal}
      className={'cr-fixed cr-right-0 cr-top-0 cr-z-999 cr-flex cr-h-full cr-w-full'}
      style={{
        justifyContent: 'end',
        alignItems: 'end',
        ...style,
      }}
    >
      <div
        onClick={(): void => {
          if (canCloseClickingOutside && onClose) {
            onClose();
          }

          if (canCloseClickingOutside && clearModal && !onClose) {
            clearModal(afterClearModal);
          }
        }}
        className={'cr-backdrop-blur-sm'}
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
        }}
      ></div>
      <div
        ref={shrinkMe}
        className={'slide cr-w-11/12 cr-rounded-t-lg cr-rounded-bl-lg '}
        css={css({
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          width: '97%',
          height: 'calc(100% - 42px)',
          overflowY: 'hidden',
          backgroundColor: 'white',
          border: 'solid #03365D 2px',
          ...modalStyle,
        })}
      >
        <ButtonContextProvider initialActions={initialActions}>
          <CloseButtons {...{ onClose, buttonList }} />
          {content ?? modalContent}
        </ButtonContextProvider>
      </div>
    </div>
  ) : null;
});
SimpleModal.displayName = 'SimpleModal';
export default SimpleModal;
export const SimpleModalWithButtons = (props: SimpleModalProps): ReactElement => (
  <SimpleModal
    {...props}
    buttonList={defaultModalButtons({
      disableOnClose: true,
      disableOnSave: true,
      disableOnRefresh: true,
      disableOnSaveAndExit: true,
    })}
  />
);
