import { RidersActionTypes, TRidersContext } from './_types';
import { riderFullName } from './getters';
import { TRider } from '../../common/types/rider';

export const ridersReducer = (
  state: TRidersContext,
  action: { type: RidersActionTypes; payload?: any | boolean },
): TRidersContext => {
  switch (action.type) {
    case RidersActionTypes.SET_DATA:
    case RidersActionTypes.SET_RIDERS: {
      const riders: any[] = action.payload || [];
      riders.sort((a: any, b: any) => (a.nickname > b.nickname ? 1 : a.nickname < b.nickname ? -1 : 0));
      return {
        ...state,
        riders,
        defaultRider: riders[0],
        ridersById: riders.reduce((acc: any, item: TRider) => {
          item.displayName = riderFullName(item);
          return { ...acc, [item.id ?? 'undefined']: item };
        }, {}),
      };
    }
    case RidersActionTypes.SET_SELECTED: {
      return {
        ...state,
        selectedRiders: action.payload?.map((rider: TRider) => rider?.id),
        defaultRider: action.payload[0],
      };
    }
    case RidersActionTypes.SET_IS_LOADING:
      return { ...state, isLoading: action.payload };
    default:
      return state;
  }
};
